@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

.banner {
    width: 100%;
    height: 73vh;
}



.line-chesse {
    width: 100%;
    border-bottom: solid 1px #fcfcfc4f;
}

.list-icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;

    color: white;
}

.telefone {

    display: flex;
    align-items: center;
    color: white;
}

.brand {

    color: white;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    margin-left: 10px;

}

.nav-main {
    display: flex;
    align-items: center;


}

.sub-brand {
    font-size: 1rem;
}

.brand-box {
    font-family:"IBM Plex Serif", serif ;
    font-weight: 400 ;
    font-size: 1.5rem;
    display: flex;
    align-items: center;

}

.menu {
    display: flex;
    align-items: center;
    list-style: none;
    margin-top: 10px;


}

.button-box {
    display: flex;
    justify-content: center;

}

.menu li {
    margin-right: 20px;
    margin-left: 20px;
}
.title-main {
    color: white;
    font-size: 4.2rem;
    font-weight: 600;
}


.sub-title {
    color: white;
    font-size: 1.5rem;
    font-weight: 400;

}

.menu li a {
    color: white;
    text-decoration: none;
    font-weight: 400;
}

.btn-cta{
    padding: 12px 25px;
    justify-self: center;
    border: none;
    background-color: rgb(110, 25, 25);
    margin-bottom: 10px;
}

@media (max-width:576px){
    body{

    }

    .menu{
        display: none !important;
    }
    .btn-banner{
        display: none !important;
    }

    .title-main {
        color: white;
        font-size: 2.2rem;
        font-weight: 700;
    }

    .sub-title {
        color: white;
        font-size: 1.5rem;
        font-weight: 400;

    }

    .brand-box {
        font-family:"IBM Plex Serif", serif ;
        font-weight: 400 ;

        font-size: 2.5vh;
        display: flex;
        align-items: center;

    }


}
