.sub-banner{


}

.text-sub{
    color: rgb(133, 38, 38);

}

.text-title{
    color: #131D3B;
    margin-bottom: 20px;
}

.box-contato{




}



.textarea{
    height: 200px;
}

.conInput{
    text-align: left;

}

.conInput input{
    width: 100%;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #131D3B;
    border-radius: 5px;
}
.box-contato h1{
    color: #131D3B;
}

.box-contato h1{
    color: #131D3B;
}
