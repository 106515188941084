
.title-red {
    color: #782323;
}
.container-card {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    gap:50px;

    padding: 50px;
    width: 100%;

    border-radius: 20px;
    position: relative;
    z-index: 1 !important;
    margin-top: 60px;

}

.container-slider {
    padding: 10px;
    background-color: #202020;
}

.caixa-html {
    height: 500px;
    max-width: 500px;
    min-height: 300px;
}

.canvas-images {

    margin-bottom: 100px;
    height: 500px;

}

.areaAtuacao-containerNames {
    background-color: #782323;
    display: flex;
    flex-wrap: wrap;
    padding: 40px;
    position: relative;
    justify-content: center;
    transform: translateY(40%);

    z-index: 999 !important;
}

.text-sobre-nos {
    margin-top: -10px;
    font-family: 'Times New Roman', Times, serif;
    color: #e1e7f8;
}

.title-sobrenos {
    color: #c4d3ff;
}

.header-sobrenos {
    color: rgb(133, 38, 38);
}

.image1 {
    width: 380px;
    height: 550px;
    border-radius: 10px;
    transition: 0.5s;
}




.text-sobre {
    font-weight: 300;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.3rem;
    text-align: justify;
}