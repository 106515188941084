@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');


.row{
	display: flex;
	flex-wrap: wrap;

}



.space-margin {
    padding-top: 0px;

}

.footer-all {
    background-color: #202020 !important;
    color: #fff !important;
    padding: 50px 0;
    margin-top: 90px;

}
.risco-vermelho {
   background-color: #782323 !important;
   position: absolute !important;
   transform: translateX(60%) !important;
   height: 100% !important;
   width: 100px !important;
   z-index: -999;
}


.title-footer {
    text-align: center;
    margin-bottom: 30px;
}

.row-list {

	display: flex;
	flex-direction: row;
	justify-content: space-around;

}



.images-list-footer {
    display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 0%;
	width: fit-content;
	background-color: red;
	gap: 300px;

	gap: 20px;



}

.links-social {

	padding: 10px;
	justify-content: center;
	gap: 10px;
}

.tw-ico {
    background-color: rgb(41, 152, 255);
    color: rgb(255, 255, 255);
    border-radius: 50%;
    display: flex;
    padding: 0.3em;
    margin: 0.3px;
	width: fit-content;
}

.in-ico {
    background-color: rgb(153, 0, 127);
    color: white;
    border-radius: 50%;
    display: flex;
    padding: 0.3em;
    margin: 0.3px;
	width: fit-content;
}

.link-ico {
    background-color: rgb(11, 114, 248);
    color: white;
    border-radius: 50%;
    display: flex;
    padding: 0.3em;
    margin: 0.3px;
	width: fit-content;
}

.flicker-ico {
	background-color: rgb(255, 0, 0);
	color: white;
	border-radius: 50%;
	display: flex;
	padding: 0.3em;
	margin: 0.3px;
	width: fit-content;
}


.yt-ico {
    background-color: rgb(153, 5, 0);
    color: white;
    border-radius: 50%;
    display: flex;
    padding: 0.3em;
    margin: 0.3px;
	width: fit-content;
}

.link-merchan {
	color: rgb(255, 255, 255);
	text-decoration: none;
}

ul{
	list-style: none;
}
.footer{
	background-color: var(--mainColor);
    padding:1px 0;
	position: relative;
	width: 100%;
	bottom: 0%;
	color: var(--textColor);
	bottom: 100%;
	z-index: 0;

}
.footer-col{
   width: 25%;
   padding: 0 15px;
}
.footer-col h4{
	font-size: 18px;
	color: var(--textColor);
	text-transform: capitalize;
	margin-bottom: 35px;
	font-weight: 500;
	position: relative;
}
.footer-col h4::before{
	content: '';
	position: absolute;
	left:0;
	bottom: -10px;
	background-color: #e91e63;
	height: 2px;
	box-sizing: border-box;
	width: 50px;
}
.footer-col ul li:not(:last-child){
	margin-bottom: 10px;
}
.footer-col ul li a{
	font-size: 16px;
	text-transform: capitalize;
	color: var(--textColor);
	text-decoration: none;
	font-weight: 300;
	color: var(--textColor);
	display: block;
	transition: all 0.3s ease;
}

.image-link {
	width: 100%;
}

.image-link img {
	width: 100%;
}

.footer-col ul li {
	transition: all 0.3s ease;
}

.footer-col ul li:hover{
	color: #ffffff;
	padding-left: 8px;
}
.footer-col .social-links a{
	display: inline-block;
	height: 40px;
	width: 40px;
	background-color: rgba(255,255,255,0.2);
	margin:0 10px 10px 0;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	color: #ffffff;
	transition: all 0.5s ease;
	padding-top: 1%;
}

.row-list div {
	width: fit-content;

}
.footer-col .social-links a:hover{
	color: #24262b;
	background-color: #ffffff;
}

.about-us-footer  {
	text-align: justify;
}

.icon {
	position: relative;
	margin-top: 20px;
}

.info-footer {

    display: flex ;
    flex-wrap: wrap;
    gap: 30px;
}

@media(max-width: 1040px) {

	.images-list-footer {
		margin-left: 0px;
		padding-left: 0px;
	}
	.images-list-footer {
		display: flex;
		flex-wrap: wrap;
		gap: 50px;

	}
}
/*responsive*/
@media(max-width: 1028px){
  .footer-col{
    width: 50%;
    margin-bottom: 30px;
}


.images-list-footer img {
    width: 100%;
}
.list-info-footer {

    background-color: rebeccapurple;
}
}
@media(max-width: 604px){
  .footer-col{
    width: 100%;
}
}

@media(max-width: 330px) {
	.row-list div {
		display: none;
	}
}