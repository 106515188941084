.areaAtucao-container {
    background-color: #9c00aa00;
    position: relative;
    margin-bottom: 50px;
    padding: 50px;
    margin-top: -41px;
    margin-bottom: 100px;


}

.container-listas {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;


}

.container-listas ul {


}

.container-listas ul li {
    list-style: none;
    margin: 10px;
    padding: 10px;
    background-color: #782323;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.5s;

}


.areaAtuacao-adv-t1 {
    color: #fff;
}

.areaAtuacao-adv-t2 {
    color: #fff;
}

.foto {

    /*
    wi:320
    he:220
    */
    max-width: 350px;
    max-height: 220px;
    min-width: 100px;
    min-height: 80px;


}

.link-about-modal {
    color: #ff9f9f;
    font-weight: bold;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
}



.box-adv {
    text-align: center;
}

.areaAtuacao-containerAreas {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;

}

.containerImages{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 40px;

}
.title-red {
    color: #782323;
    margin-bottom: 60px;
    text-align: center;
    align-self: center;

}
.c-At {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

@media (max-width:1099px){
    .containerImages{
        width: 100% !important;
        background-color: #782323;

    }
    .containerImages img {
        width: 100%;
        height: 100%;
    }
}

@media (max-width:576px){
    .canvas-images {
        display: none;
    }
    .c-At {
        display: flex;
        flex-direction: column;

        padding: 0;

    }
    .containerImages{
        width: 100% !important;

    }
}