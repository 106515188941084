.container-faleConosco{

    padding: 70px;
    background-color: white;
    margin-top: -100px;
    border-radius: 10px;
}


.title-faleConosco {
    color: #A53B47;
}

.faleConosco-col1 {
    margin-left: 20px;
}

.faleConosco-col2 input {
    height: 40px;
}

.faleConosco-col3 input {
    height: 40px;
}

.faleConosco-col4 {
    display: flex;
    align-items: flex-end;

}

.sub-title-faleConosco {
    font-weight: 500;
    color: #131D3B;
}

.btn-enviar {
    padding: 10px 35px!important;
    margin-left: 30px;

}


@media (max-width:576px){
    .faleConosco-col1 {
        margin-left: 0px;
    }
}
